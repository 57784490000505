import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import { createGlobalStyle } from 'styled-components'

import Navbar from './NavBar'
import Footer from './Footer'
import SEO from './SEO'
import { Helmet } from 'react-helmet'

const Page = styled.div`
  ${tw`h-screen`}
  ${(props) => props.disableScroll && tw`overflow-hidden`}
`

const PageContainer = tw.div`
  relative
  flex
  flex-col
  min-h-screen
`

const ContentWrap = tw.main`
  flex-grow
`

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/inter/v2/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZ9hiA.woff2)
        format('woff2'),
      url(https://fonts.gstatic.com/s/inter/v2/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZ9hjg.woff)
        format('woff');
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/inter/v2/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZ9hiA.woff2)
        format('woff2'),
      url(https://fonts.gstatic.com/s/inter/v2/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZ9hjg.woff)
        format('woff');
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/inter/v2/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYAZ9hiA.woff2)
        format('woff2'),
      url(https://fonts.gstatic.com/s/inter/v2/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYAZ9hjg.woff)
        format('woff');
  }

  body {
    ${tw`
      bg-ui-background
      text-text-01
      font-sans`}
  }
`

const navItems = {
  primary: [
    { name: 'Home', to: '/' },
    // { name: 'Over Ons', to: '/over' },
    { name: 'Evenementen', to: '/evenementen', partiallyActive: true },
    { name: 'Diensten', to: '/diensten' }
  ],
  secondary: [
    // { name: 'Waarom Bio-Ingenieurs', to: '/bio-ingenieurs' },
    {
      name: 'Cv Upload',
      to:
        'https://docs.google.com/forms/d/e/1FAIpQLSc0PwxwemgeSaIjayqADiSjM2pKdINat30Hn1R19RsR48CUmg/viewform',
      isExternal: true
    },
    { name: 'Contact', to: 'mailto:pbr@boerekot.be', isExternal: true }
  ]
}

const socialLinks = {
  linkedin:
    'https://www.linkedin.com/company/vlk-public-and-business-relations/',
  facebook: 'https://www.facebook.com/PBRGent/',
  instagram: 'https://www.instagram.com/vlkgent/'
}

const Layout = ({ children }) => {
  const [navOpen, setNavOpen] = useState(false)

  return (
    <Page disableScroll={navOpen}>
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#00aba9" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <GlobalStyle />
      <SEO />
      <PageContainer>
        <Navbar
          open={navOpen}
          navItems={navItems}
          socialLinks={socialLinks}
          onOpen={() => setNavOpen(true)}
          onClose={() => setNavOpen(false)}
        />
        <ContentWrap>{children}</ContentWrap>
        <Footer navItems={navItems} socialLinks={socialLinks} />
      </PageContainer>
    </Page>
  )
}

export default Layout
