import tw from 'twin.macro'

export const H1 = tw.h1`
  text-4xl
  md:(text-5xl)
  font-bold
  leading-normal
`

export const H2 = tw.h2`
  mt-10
  text-4xl
  font-semibold
  leading-normal
`

export const H3 = tw.h3`
  mt-10
  text-2xl
  font-semibold
  leading-normal
`

export const Text = tw.p`
  my-4
  text-base
  font-normal
  leading-relaxed
`
