import React from 'react'
import styled from 'styled-components'
import tw, { theme } from 'twin.macro'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Menu, Close, External } from 'css.gg/icons/all'

import Img from 'gatsby-image'

import { Container, NavItem, IconNavItem, NavLink, GLink } from './common'
import SocialLinks from './SocialLinks'

const ExternalSmall = styled(External)`
  --ggs: 0.7;
`

const Header = tw.header`
  relative
  h-20
  bg-ui-02
  border-b-4
  border-decorative-03
`

const HeaderContainer = tw(Container)`
  flex
  h-full
  justify-between
`

const Burger = tw.button`
  flex
  justify-center
  items-center
  mr-4
  h-10
  w-10
  hocus:outline-none
`

const OpenBurger = (props) => (
  <Burger {...props}>
    <Menu />
  </Burger>
)

const CloseBurger = (props) => (
  <Burger {...props}>
    <Close />
  </Burger>
)

const HeaderNav = tw.nav`
  flex
`

const HeaderNavMenu = tw.div`
  hidden
  lg:flex
  mr-6
  border-r
  border-decorative-01
`

const HeaderNavLink = styled(GLink)`
  ${tw`relative px-4`}
  &.active {
    ${tw`after:(content absolute w-full bottom-0 inset-x-0 border-b-8 border-decorative-02)`}
  }
`

const Nav = styled.nav`
  ${tw`
    absolute
    inset-x-0
    top-20
    bottom-0
    overflow-hidden
    transition-visibility
    z-50`}

  ${(props) => (props.open ? tw`visible` : tw`invisible delay-600`)}
`

const NavBackground = styled.div`
  ${tw`
    absolute
    inset-0
    transition-opacity ease-in
    z-0`}

  background-color: #221E1E;

  ${(props) =>
    props.visible ? tw`opacity-25 duration-150` : tw`opacity-0 duration-300`}
`

const NavMain = styled.div`
  ${tw`
    relative
    bg-ui-02
    z-10
    overflow-scroll
    transform
    transition-transform duration-300 ease-out`}

  max-height: calc(100vh - ${theme`spacing.20`});

  ${(props) =>
    props.open ? tw`translate-y-0` : tw`-translate-y-full delay-300`}
`

const NavContainer = styled(Container)`
  ${tw`
    pt-8
    pb-8
    md:px-10
    transition-opacity ease-in duration-300`}

  ${(props) =>
    props.contentVisible ? tw`opacity-100 delay-200` : tw`opacity-0`}
`

const NavMenu = tw.div`
  flex
  flex-col
  md:flex-row
  lg:flex-col
`

const NavMenuStart = tw.div`
  border-b
  border-decorative-01
  lg:hidden
  md:w-1/3 text-2xl
`

const NavMenuEnd = tw.div`
  mt-4
  md:mt-0
  lg:w-1/2
  border-b
  border-decorative-01
  md:border-none
  text-lg
  lg:text-2xl
  lg:self-end
`

const NavFooter = tw.div`
  mt-6
`

const Navbar = ({ open, navItems, socialLinks, onOpen, onClose }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      schild: file(relativePath: { eq: "schild.png" }) {
        childImageSharp {
          fixed(height: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div>
      <Header>
        <HeaderContainer>
          <Link tw="flex justify-center items-center" to="/">
            <Img fixed={data.logo.childImageSharp.fixed} alt="vlk-pbr logo" />
          </Link>
          <HeaderNav>
            <HeaderNavMenu>
              {navItems.primary.map((navItem, index) => (
                <NavItem
                  as={HeaderNavLink}
                  to={navItem.to}
                  partiallyActive={navItem.partiallyActive}
                  key={index}
                >
                  {navItem.name}
                </NavItem>
              ))}
            </HeaderNavMenu>
            <NavItem onClick={() => (open ? onClose() : onOpen())}>
              {open ? <CloseBurger /> : <OpenBurger />}
            </NavItem>
            <NavItem
              tw="flex justify-center items-center w-12"
              as="a"
              href="https://boerekot.be"
            >
              <Img fixed={data.schild.childImageSharp.fixed} alt="vlk logo" />
            </NavItem>
          </HeaderNav>
        </HeaderContainer>
      </Header>

      <Nav open={open}>
        <NavBackground visible={open} onClick={onClose} />
        <NavMain open={open}>
          <NavContainer contentVisible={open}>
            <NavMenu>
              <NavMenuStart tw="lg:hidden md:w-1/3 text-2xl">
                {navItems.primary.map((navItem, index) => (
                  <NavItem
                    as={NavLink}
                    to={navItem.to}
                    partiallyActive={navItem.partiallyActive}
                    key={index}
                  >
                    {navItem.name}
                  </NavItem>
                ))}
              </NavMenuStart>
              <NavMenuEnd tw="text-lg md:border-none lg:self-end lg:w-1/3">
                {navItems.secondary.map((navItem, index) =>
                  navItem.isExternal ? (
                    <IconNavItem
                      tw="text-2xl"
                      as="a"
                      href={navItem.to}
                      key={index}
                    >
                      {navItem.name}
                      <ExternalSmall tw="ml-3 mr-3" />
                    </IconNavItem>
                  ) : (
                    <NavItem as={NavLink} to={navItem.to} key={index}>
                      {navItem.name}
                    </NavItem>
                  )
                )}
              </NavMenuEnd>
            </NavMenu>
            <NavFooter>
              <div>VLK Gent</div>
              <div>Coupure Links 653</div>
              <div>9000 Gent</div>
              <a
                tw="text-decorative-03 hover:text-decorative-02"
                href="mailto:pbr@boerekot.be"
              >
                pbr@boerekot.be
              </a>
              <SocialLinks links={socialLinks} />
            </NavFooter>
          </NavContainer>
        </NavMain>
      </Nav>
    </div>
  )
}

export default Navbar
