import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLinkedinIn,
  faFacebookF,
  faInstagram
} from '@fortawesome/free-brands-svg-icons'

export const StyledSocialLinks = tw.div`
  flex
  py-4
  space-x-4
`

export const SocialIcon = styled.a`
  ${tw`
    flex
    justify-center
    items-center
    h-8
    w-8
    rounded-full
    bg-interactive-01
    text-text-04
    transform
    transition-transform duration-300 ease-in-out
    hocus:scale-125`}
  ${(props) => props.inverse && tw`bg-ui-02 text-ui-05`}
`

const SocialLinks = ({ links = {}, inverse }) => (
  <StyledSocialLinks>
    <SocialIcon inverse={inverse} href={links.linkedin || '#'}>
      <FontAwesomeIcon icon={faLinkedinIn} />
    </SocialIcon>
    <SocialIcon inverse={inverse} href={links.facebook || '#'}>
      <FontAwesomeIcon icon={faFacebookF} />
    </SocialIcon>
    <SocialIcon inverse={inverse} href={links.instagram || '#'}>
      <FontAwesomeIcon icon={faInstagram} />
    </SocialIcon>
  </StyledSocialLinks>
)

export default SocialLinks
