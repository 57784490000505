import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Link } from 'gatsby'

export const Container = tw.div`
  max-w-screen-xl
  mx-auto
  px-4
`

export const NavItem = tw.div`
  flex
  items-center
  hocus:text-interactive-01
`

export const IconNavItem = tw(NavItem)`
  justify-between
`

export const GLink = (props) => (
  <Link {...props} activeClassName="active" children={props.children} />
)

export const NavLink = styled(GLink)`
  &.active {
    ${tw`text-interactive-01`}
  }
`
