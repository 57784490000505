import React from 'react'
import tw from 'twin.macro'
import { External } from 'css.gg/icons/all'

import { Container, NavItem, IconNavItem, NavLink } from './common'
import SocialLinks from './SocialLinks'
import styled from 'styled-components'

const StyledFooter = tw.footer`
  w-full
  bg-ui-05
  border-t-2
  border-decorative-03
`

const FooterContainer = tw(Container)`
  flex
  flex-col
  pt-4
  text-text-04
`

const Nav = tw.nav`
  flex
  flex-col
  md:flex-row
  text-2xl
  md:text-base
  md:space-x-5
`

const Copy = tw.div`
  md:mt-16
  py-8
  border-t
  border-decorative-01
`

const ExternalSmall = styled(External)`
  --ggs: 0.7;
`

const Footer = ({ navItems, socialLinks }) => (
  <StyledFooter>
    <FooterContainer>
      <div tw="flex flex-col md:flex-row">
        <div>
          <Nav>
            {navItems.primary.map((navItem, index) => (
              <NavItem key={index} as={NavLink} to={navItem.to}>
                {navItem.name}
              </NavItem>
            ))}
          </Nav>
          <div tw="mt-4">
            {navItems.secondary.length > 0 && (
              <div tw="py-2 border-b border-decorative-01 text-sm text-decorative-01">
                Andere Links
              </div>
            )}
            <div tw="mt-4 flex flex-col">
              {navItems.secondary.map((navItem, index) =>
                navItem.isExternal ? (
                  <IconNavItem
                    key={index}
                    tw="text-xl"
                    as="a"
                    href={navItem.to}
                  >
                    {navItem.name}
                    <ExternalSmall tw="mr-2" />
                  </IconNavItem>
                ) : (
                  <NavItem key={index} as={NavLink} to={navItem.to}>
                    {navItem.name}
                  </NavItem>
                )
              )}
            </div>
          </div>
        </div>
        <div tw="flex-grow"></div>
        <div tw="md:self-end md:w-1/3 mt-16 md:mt-0">
          <div tw="mt-4">
            <div>VLK Gent</div>
            <div>Coupure Links 653</div>
            <div>9000 Gent</div>
          </div>
          <a
            tw="block mt-2 hover:text-decorative-03"
            href="mailto:pbr@boerekot.be"
          >
            pbr@boerekot.be
          </a>
          <SocialLinks links={socialLinks} inverse tw="bg-ui-01" />
        </div>
      </div>
      <Copy>
        <p>&copy; {new Date().getFullYear()} VLK Gent</p>
      </Copy>
    </FooterContainer>
  </StyledFooter>
)

export default Footer
